:where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  padding: 14px;
}

img.ant-image-preview-img {
  display: inline-block;
}

.carousel {
  display: none;
}

.carousel-slider {
  padding-bottom: 24px;
  display: flex;
}

.control-dots {
  margin: 0px !important;
}

.control-dots .dot {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  height: 3px !important;
  width: 60px !important;
  background-color: #bababa !important;
  border: none !important;
  opacity: 1 !important;
}

.control-dots .selected {
  background-color: #202020 !important;
}

.trending_cards::-webkit-scrollbar {
  display: none;
}
.trending_cards {
  scrollbar-width: none;
}

@media (max-width: 525px) {
  .control-dots .dot {
    height: 4px !important;
    width: 10% !important;
  }
}

.liquid_gold_text_box {
  font-family: 'Bricolage Grotesque', sans-serif !important;
  font-weight: 900;
  position: relative;
}

.liquid_gold_text_box > h1:first-child {
  text-shadow: 0px 0px 10px #f29100;
}

.liquid_gold_text_box > h1:last-child {
  text-shadow: 0px 0px 4px #f29100;
  line-height: 36px;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #181eac;
  border-color: #181eac;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #181eac;
  border-color: #181eac;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-checkbox-checked:after {
  border: 2px solid #181eac;
}

.confirm-order-body {
  position: sticky;
  top: 200px;
}

.fixed-component {
  position: fixed;
  z-index: 90;
  background-color: white;
  width: 100%;
  height: 120px;
  margin-top: -25px;
}

.fixed-component-child {
  margin-top: 15px;
  width: 90%;
}

.gradient-button {
  color: white;
  background: linear-gradient(270deg, #4f46e5 0%, #fb7185 100%);
  box-shadow: 0 0 4px rgba(185, 185, 187, 0.75);
}

.gradient-button:hover {
  background: linear-gradient(90deg, #4f46e5 0%, #fb7185 100%);
  color: white !important;
}

.gradient-button:hover .bannerArrow path {
  fill: white;
}

.font-Bricolage {
  font-family: 'Bricolage Grotesque', sans-serif !important;
}

.banner-btn-text {
  font-family: 'Inter', sans-serif;
  line-height: 22px;
}

.banner-text > h1 {
  font-family: 'Bricolage Grotesque', sans-serif !important;
}

.banner-text {
  position: relative;
  text-align: left;
  z-index: 11;
  color: white;
  /* background-color: black; */
}

.collectible_banner_text {
  font-weight: 900;
  line-height: 52px;
  background: linear-gradient(90deg, #4f46e5 0%, #fb7185 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.staking_banner_text {
  font-weight: 900;
}

.stake-banner-stats{
  background-color: rgba(36, 33, 33, 0.293);
  padding: 12px;
  border-radius: 12px;
  position: relative;
  top: -28px !important;
  left: -360px;
  display: flex;
  justify-content: space-between;
}

.stake-banner-stats-title{
  font-size: 15px;
  /* color: white; */
}

.stake-banner-stats-value{
  font-size: 30px;
  /* color: white; */
}

.clothing_banner_text {
  font-weight: 900;
}

.metal_banner_text {
  font-weight: 900;
}

.liquid_gold_text_box {
  top: 20%;
  margin-left: 4%;
}

.token_banner_text_box {
  font-weight: 900;
}

.how_banner_text_box {
  font-family: 'Bricolage Grotesque', sans-serif !important;
  font-weight: 900;
  position: relative;
}

.token_banner_text1 {
  color: #4b63d3;
  font-weight: 600;
}

.token_banner_text2 {
  background: linear-gradient(90deg, #ffa010 0%, #5d10ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.banner-block-container {
  z-index: 24;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.banner-block {
  position: relative;
  background-color: white;
  z-index: 22;
  box-shadow: 0px 3px 12px darkgrey;
}

.banner-step {
  color: #c2c0c0;
  font-weight: 600;
  font-family: 'Bricolage Grotesque', sans-serif !important;
}

.banner-step-description {
  color: #2e51cd;
  max-width: 120px;
  font-family: 'Bricolage Grotesque', sans-serif !important;
}

.banner-arrow {
  z-index: 24;
}

.payment-dropdown > .ant-select-item-option-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.selected-payment-option > .ant-select-selector > .ant-select-selection-item {
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.selected-payment-option
  > .ant-select-selector
  > .ant-select-selection-placeholder {
  color: white;
  font-weight: 600;
}

.checkout-card {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 5px;
}

.mobile-checkout-card {
  box-shadow: 0px 4px 4px 0px #00000040;
}

@media (min-width: 1600px) {
  .banner-block-container {
    margin-top: 30px;
  }

  .banner-block {
    width: 200px;
    height: 200px;
    border-radius: 0px 84px 0px 0px;
    padding: 28px;
    text-align: left;
    line-height: 20px;
  }

  .banner-step {
    font-size: 12px;
    color: #c2c0c0;
    margin-top: 12px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 16px;
    color: #2e51cd;
    margin-top: 5px;
    max-width: 120px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 42px !important;
    height: 48px;
  }

  .banner-arrow {
    width: 27px !important;
    height: 27px !important;
  }

  .rwa-class {
    width: 80px;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .banner-block-container {
    margin-top: 50px;
  }

  .banner-block {
    width: 166px;
    height: 166px;
    border-radius: 0px 84px 0px 0px;
    padding: 20px;
    text-align: left;
    line-height: 20px;
  }

  .banner-step {
    font-size: 10.5px;
    color: #c2c0c0;
    margin-top: 12px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 13px;
    color: #2e51cd;
    margin-top: 5px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 35px !important;
    height: 40px;
  }

  .banner-arrow {
    width: 27px !important;
    height: 27px !important;
  }

  .rwa-class {
    width: 80px;
  }
}

@media (min-width: 1201px) and (max-width: 1399px) {
  .banner-block-container {
    margin-top: 50px;
  }

  .banner-block {
    width: 150px;
    height: 150px;
    border-radius: 0px 64px 0px 0px;
    padding: 20px;
    text-align: left;
    line-height: 20px;
  }

  .banner-step {
    font-size: 10.5px;
    color: #c2c0c0;
    margin-top: 12px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 13px;
    color: #2e51cd;
    margin-top: 5px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 35px !important;
    height: 40px;
  }

  .banner-arrow {
    width: 27px !important;
    height: 27px !important;
  }

  .rwa-class {
    width: 80px;
  }
}

@media (min-width: 1200px) {
  .collectible_banner_text {
    width: 520px;
    font-size: 48px;
    left: 7%;
    top: 15%;
  }

  .staking_banner_text {
    font-size: 48px;
    left: 7%;
    top: -10%;
    line-height: 52px;
  }

  .staking_banner_text > h1:nth-child(3){
    line-height: 30px;
  }

  .stake-banner-stats{
    top: -20px;
    right: -20px;
  }

  .clothing_banner_text {
    width: 485px;
    font-size: 48px;
    left: 7%;
    top: 15%;
    line-height: 52px;
  }

  .metal_banner_text {
    width: 485px;
    font-size: 48px;
    left: 7%;
    top: 15%;
    line-height: 52px;
  }

  .liquid_gold_text_box {
    top: 11%;
    margin-left: 7%;
  }

  .liquid_gold_text_box > h1:first-child {
    font-size: 48px;
  }

  .liquid_gold_text_box > h1:last-child {
    font-size: 30px;
  }

  .token_banner_text_box {
    top: 5%;
    left: 7%;
    width: 80%;
  }

  .token_banner_text1 {
    width: 100%;
    font-size: 20px;
  }

  .token_banner_text2 {
    width: 450px;
    font-size: 48px;
    margin-top: 20px;
    line-height: 52px;
  }

  .how_banner_text_box {
    top: 15%;
    left: 5%;
    font-size: 44px;
    font-weight: 900;
    height: 450px;
  }

  .rwa-class {
    width: 80px;
  }

  .swiper-pagination-bullets {
    margin-top: -40px;
    position: relative !important;
  }

  .product-detail-swiper {
    max-width: 450px;
  }
}

@media (min-width: 1000px) and (max-width: 1199px) {
  .collectible_banner_text {
    width: 360px;
    font-size: 44px;
    left: 7%;
    top: 5%;
    line-height: 42px;
  }

  .staking_banner_text {
    font-size: 48px;
    left: 7%;
    top: -13%;
    line-height: 52px;
  }

  .staking_banner_text > h1:nth-child(3){
    line-height: 28px;
  }

  .stake-banner-stats{
    top: -20px;
    left: -120px !important;
  }

  .clothing_banner_text {
    width: 420px;
    font-size: 48px;
    left: 7%;
    top: 5%;
    line-height: 52px;
  }

  .metal_banner_text {
    width: 420px;
    font-size: 48px;
    left: 7%;
    top: 10%;
    line-height: 52px;
  }

  .token_banner_text_box {
    top: 5%;
    left: 6%;
    width: 80%;
  }

  .token_banner_text1 {
    width: 100%;
    font-size: 20px;
  }

  .token_banner_text2 {
    width: 450px;
    font-size: 48px;
    margin-top: 20px;
    line-height: 52px;
  }

  .liquid_gold_text_box {
    top: 3%;
    margin-left: 7%;
  }

  .liquid_gold_text_box > h1:first-child {
    font-size: 48px;
  }

  .liquid_gold_text_box > h1:last-child {
    font-size: 30px;
  }

  .how_banner_text_box {
    top: 15%;
    left: 5%;
    font-size: 36px;
    font-weight: 900;
    height: 450px;
  }

  .banner-block-container {
    margin-top: 50px;
  }

  .banner-block {
    width: 130px;
    height: 130px;
    border-radius: 0px 44px 0px 0px;
    padding: 10px;
    text-align: left;
  }

  .banner-step {
    font-size: 10px;
    color: #c2c0c0;
    margin-top: 8px;
    font-weight: 700;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 12px;
    color: #2e51cd;
    margin-top: 5px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 20px !important;
    height: 24px;
  }

  .banner-arrow {
    width: 12px !important;
    height: 12px !important;
  }

  .rwa-class {
    width: 80px;
  }

  .swiper-pagination-bullets {
    margin-top: -40px;
    position: relative !important;
  }

  .product-detail-swiper {
    max-width: 450px;
  }
}

@media (min-width: 769px) and (max-width: 834px) {
  .staking_banner_text {
    font-size: 44px;
    left: 7%;
    top: -15%;
    /* line-height: 22px; */
  }

  .staking_banner_text > h1:nth-child(3){
    line-height: 28px;
  }

  .stake-banner-stats{
    top: -20px !important;
    left: -60px;
  }

  .stake-banner-stats-title{
    font-size: 12px;
  }

  .stake-banner-stats-value{
    font-size: 24px;
  }

}
@media (min-width: 835px) and (max-width: 999px) {
  .staking_banner_text {
    font-size: 44px;
    left: 7%;
    top: -15%;
  }

  .staking_banner_text > h1:nth-child(3){
    line-height: 32px;
  }

  .stake-banner-stats{
    top: -20px !important;
    left: -60px;
  }
}

@media (min-width: 769px) and (max-width: 999px) {
  .collectible_banner_text {
    width: 360px;
    font-size: 40px;
    left: 7%;
    top: 10%;
    line-height: 42px;
  }

  /* .staking_banner_text {
    font-size: 44px;
    left: 7%;
    top: -5%;
    line-height: 42px;
  } */

  .clothing_banner_text {
    width: 360px;
    font-size: 44px;
    left: 7%;
    top: 8%;
    line-height: 42px;
  }

  .metal_banner_text {
    width: 400px;
    font-size: 44px;
    left: 7%;
    top: 15%;
    line-height: 42px;
  }

  .liquid_gold_text_box {
    top: 8%;
    margin-left: 7%;
  }

  .liquid_gold_text_box > h1:first-child {
    font-size: 48px;
  }

  .liquid_gold_text_box > h1:last-child {
    font-size: 30px;
  }

  .token_banner_text_box {
    top: 5%;
    left: 6%;
    width: 80%;
  }

  .token_banner_text1 {
    width: 100%;
    font-size: 20px;
  }

  .token_banner_text2 {
    width: 450px;
    font-size: 48px;
    margin-top: 20px;
    line-height: 52px;
  }

  .how_banner_text_box {
    top: 15%;
    left: 2%;
    font-size: 28px;
    font-weight: 900;
  }

  .banner-block-container {
    margin-top: 30px;
    width: 90%;
    margin-left: 0%;
  }

  .banner-block {
    width: 90px;
    height: 90px;
    border-radius: 0px 24px 0px 0px;
    padding: 10px;
    text-align: left;
  }

  .banner-step {
    font-size: 6px;
    color: #c2c0c0;
    margin-top: 10px;
    font-weight: 700;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 7px;
    color: #2e51cd;
    margin-top: 5px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 14px !important;
    height: 14px;
  }

  .banner-arrow {
    width: 12px !important;
    height: 12px !important;
  }

  .rwa-class {
    width: 55px;
  }

  .product-detail-swiper {
    max-width: 500px;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .collectible_banner_text {
    width: 300px;
    font-size: 40px;
    left: 7%;
    top: 10%;
    line-height: 44px;
  }

  .stake-banner-stats{
    top: -15px;
    left: -10px !important;
    width: 66% !important;
  }

  .stake-banner-stats-title{
    font-size: 12px;
  }

  .stake-banner-stats-value{
    font-size: 24px;
  }

  .staking_banner_text {
    font-size: 28px;
    left: 7%;
    top: -10%;
  }

  .staking_banner_text > h1:nth-child(3){
    line-height: 28px;
  }

  .staking_banner_text > .title {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .clothing_banner_text {
    width: 400px;
    font-size: 40px;
    left: 7%;
    top: 10%;
    line-height: 44px;
  }

  .metal_banner_text {
    width: 320px;
    font-size: 40px;
    left: 7%;
    top: 10%;
    line-height: 44px;
  }

  .liquid_gold_text_box {
    top: 7%;
    margin-left: 7%;
  }

  .liquid_gold_text_box > h1:first-child {
    font-size: 48px;
  }

  .liquid_gold_text_box > h1:last-child {
    font-size: 30px;
  }

  .token_banner_text_box {
    top: 10%;
    left: 8%;
    width: 80%;
  }

  .token_banner_text1 {
    width: 100%;
    font-size: 20px;
  }

  .token_banner_text2 {
    width: 300px;
    font-size: 30px;
    margin-top: 20px;
    line-height: 36px;
  }

  .how_banner_text_box {
    top: 10px;
    left: 2%;
    font-size: 48px;
    font-weight: 900;
  }

  .banner-block-container {
    margin-top: 20px;
    width: 90%;
    margin-left: 1%;
  }

  .banner-block {
    width: 90px;
    height: 90px;
    border-radius: 0px 24px 0px 0px;
    padding: 10px;
    text-align: left;
  }

  .banner-step {
    font-size: 6px;
    color: #c2c0c0;
    margin-top: 10px;
    font-weight: 700;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 7px;
    color: #2e51cd;
    margin-top: 5px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 14px !important;
    height: 14px;
  }

  .banner-arrow {
    width: 12px !important;
    height: 12px !important;
  }

  .rwa-class {
    width: 55px;
  }

  .product-detail-swiper {
    max-width: 600px;
  }
}

@media (min-width: 481px) and (max-width: 599px) {
  .collectible_banner_text {
    width: 250px;
    font-size: 36px;
    left: 7%;
    top: 20%;
    line-height: 36px;
  }

  .stake-banner-stats{
    width: 60%;
    left: -10px;
    top: -10px !important;
  }

  .stake-banner-stats-title{
    font-size: 8px;
    font-weight: 600;
  }

  .stake-banner-stats-value{
    font-size: 20px;
  }

  .staking_banner_text {
    font-size: 34px;
    left: 7%;
    top: -7%;
    line-height: 36px;
  }

  .staking_banner_text > h1:nth-child(3){
    line-height: 24px;
  }

  .staking_banner_text > .title {
    font-size: 40px;
  }

  .clothing_banner_text {
    width: 300px;
    font-size: 32px;
    left: 7%;
    top: 20%;
    line-height: 36px;
  }

  .metal_banner_text {
    width: 300px;
    font-size: 32px;
    left: 7%;
    top: 20%;
    line-height: 36px;
  }

  .metal_banner_text > h1:first-child {
    width: 120px;
  }

  .liquid_gold_text_box {
    top: 18%;
    font-size: 42px;
    margin-left: 7%;
  }

  .liquid_gold_text_box > h1:first-child {
    font-size: 40px;
  }

  .liquid_gold_text_box > h1:last-child {
    font-size: 30px;
  }

  .token_banner_text_box {
    top: 5%;
    left: 8%;
    width: 80%;
  }

  .token_banner_text1 {
    width: 240px;
    font-size: 18px;
    line-height: 24px;
  }

  .token_banner_text2 {
    width: 260px;
    font-size: 28px;
    line-height: 32px;
    margin-top: 15px;
  }

  .how_banner_text_box {
    top: 10px;
    left: 1%;
    font-size: 36px;
    font-weight: 900;
  }

  .banner-block-container {
    margin-top: 40px;
    width: 95%;
    margin-left: 1%;
  }

  .banner-block {
    width: 80px;
    height: 80px;
    border-radius: 0px 24px 0px 0px;
    padding: 10px;
    text-align: left;
  }

  .banner-step {
    font-size: 6px;
    color: #c2c0c0;
    margin-top: 10px;
    font-weight: 700;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 7px;
    color: #2e51cd;
    margin-top: 5px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 11px !important;
    height: 11px;
  }

  .banner-arrow {
    width: 12px !important;
    height: 12px !important;
  }

  .rwa-class {
    width: 55px;
  }

  .product-detail-swiper {
    max-width: 480px;
  }
}

@media (min-width: 400px) and (max-width: 480px) {
  .collectible_banner_text {
    width: 180px;
    font-size: 24px;
    left: 4%;
    top: 5%;
    line-height: 24px;
  }

  .stake-banner-stats {
    width: 60%;
    left: 20px;
    top: -18px !important;
  }

  .stake-banner-stats-title{
    font-size: 8px;
    font-weight: 600;
  }

  .stake-banner-stats-value{
    font-size: 16px;
  }

  .staking_banner_text {
    font-size: 20px;
    left: 4%;
    top: -30%;
    line-height: 24px;
  }

  .staking_banner_text > .title{
    font-size: 24px;
  }

  .staking_banner_text > h1:nth-child(2){
    margin-top: 6px;
    font-size: 16px;
  }

  .staking_banner_text > h1:nth-child(3){
    margin-top: 6px;
    font-size: 16px;
    width: 68%;
    line-height: 18px;
  }

  .clothing_banner_text {
    width: 220px;
    font-size: 24px;
    left: 4%;
    top: 5%;
    line-height: 24px;
  }

  .metal_banner_text {
    width: 220px;
    font-size: 24px;
    left: 4%;
    top: 5%;
    line-height: 24px;
  }

  .metal_banner_text > h1:first-child {
    width: 120px;
  }

  .liquid_gold_text_box {
    top: 3%;
    margin-left: 4%;
  }

  .liquid_gold_text_box > h1:first-child {
    font-size: 30px;
  }

  .liquid_gold_text_box > h1:last-child {
    font-size: 20px;
  }

  .token_banner_text_box {
    top: 0%;
    left: 5%;
    width: 80%;
  }

  .token_banner_text1 {
    width: 170px;
    font-size: 12px;
    left: 4%;
    top: -10%;
    line-height: 16px;
  }

  .token_banner_text2 {
    width: 200px;
    font-size: 22px;
    left: 4%;
    top: -10%;
    line-height: 20px;
    margin-top: 5px;
  }

  .how_banner_text_box {
    top: -10px;
    left: -1%;
    font-size: 20px;
    font-weight: 900;
  }

  .banner-block-container {
    width: 95%;
    margin-left: -1%;
  }

  .banner-block {
    width: 70px;
    height: 70px;
    border-radius: 0px 24px 0px 0px;
    padding: 4px;
    text-align: left;
  }

  .banner-step {
    font-size: 6px;
    color: #c2c0c0;
    margin-top: 10px;
    font-weight: 700;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 7px;
    color: #2e51cd;
    margin-top: 2px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 11px !important;
    height: 11px;
  }

  .banner-arrow {
    width: 12px !important;
    height: 12px !important;
  }

  .rwa-class {
    width: 55px;
  }

  .product-detail-swiper {
    max-width: 400px;
  }
}

@media (max-width: 399px) {
  .collectible_banner_text {
    width: 180px;
    font-size: 24px;
    left: 4%;
    top: 8%;
    line-height: 24px;
  }

  .stake-banner-stats{
    width: 60%;
    top: -20px !important;
    left: 10px;
    height: 180px;
  }

  .stake-banner-stats-title{
    font-size: 8px;
    font-weight: 600;
  }

  .stake-banner-stats-value{
    font-size: 16px;
  }

  .staking_banner_text {
    font-size: 24px;
    left: 4%;
    top: -82%;
    line-height: 24px;
  }

  .staking_banner_text > .title{
    font-size: 20px;
  }

  .staking_banner_text > h1:nth-child(2){
    margin-top: 6px;
    font-size: 14px;
  }

  .staking_banner_text > h1:nth-child(3){
    margin-top: 6px;
    font-size: 14px;
    width: 68%;
    line-height: 16px;
  }

  .clothing_banner_text {
    width: 220px;
    font-size: 24px;
    left: 4%;
    top: 10%;
    line-height: 24px;
  }

  .metal_banner_text {
    width: 220px;
    font-size: 24px;
    left: 4%;
    top: 10%;
    line-height: 24px;
  }

  .metal_banner_text > h1:first-child {
    width: 120px;
  }

  .token_banner_text_box {
    top: 0%;
    left: 5%;
    width: 80%;
  }

  .token_banner_text1 {
    width: 170px;
    font-size: 12px;
    left: 4%;
    top: -10%;
    line-height: 16px;
  }

  .token_banner_text2 {
    width: 200px;
    font-size: 22px;
    left: 4%;
    top: -10%;
    line-height: 20px;
    margin-top: 5px;
  }

  .liquid_gold_text_box {
    top: 5%;
    font-size: 28px;
    margin-left: 14px;
  }

  .liquid_gold_text_box > h1:first-child {
    font-size: 30px;
  }

  .liquid_gold_text_box > h1:last-child {
    font-size: 20px;
  }

  .how_banner_text_box {
    top: -10px;
    left: -2%;
    font-size: 20px;
  }

  .banner-block-container {
    width: 95%;
    margin-left: -1%;
  }

  .banner-block {
    width: 70px;
    height: 70px;
    border-radius: 0px 24px 0px 0px;
    padding: 4px;
    text-align: left;
  }

  .banner-step {
    font-size: 6px;
    color: #c2c0c0;
    margin-top: 10px;
    font-weight: 700;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-step-description {
    font-size: 7px;
    color: #2e51cd;
    margin-top: 2px;
    font-weight: 600;
    font-family: 'Bricolage Grotesque', sans-serif !important;
  }

  .banner-icons {
    width: 11px !important;
    height: 11px;
  }

  .banner-arrow {
    width: 12px !important;
    height: 12px !important;
  }

  .rwa-class {
    width: 55px;
  }

  .product-detail-swiper {
    max-width: 330px;
  }
}

.swiper-slide {
  opacity: 0 !important;
}

.swiper-slide-active {
  opacity: 1 !important;
}

/* .swiper-pagination-bullets{
  margin-top: -40px;
  position: relative !important;
} */

.swiper-button-prev:after {
  content: '' !important;
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../../images/resources/carouselArrow.png');
  background-size: contain;
  background-repeat: no-repeat;
  transform: scale(2) rotate(180deg);
  margin-top: 140px;
}

.swiper-button-next:after {
  content: '' !important;
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../../images/resources/carouselArrow.png');
  background-size: contain;
  background-repeat: no-repeat;
  transform: scale(2);
  margin-top: 140px;
}

.ant-table-pagination {
  justify-content: center !important;
}

.custom-select-no-tick
  .ant-select-item-option-selected
  .ant-select-item-option-state {
  display: none !important;
}

.custom-check-icon {
  color: #28a745;
  font-size: 18px;
  position: absolute;
  right: 7px;
}
